// These settings will be different per environment
const REACT_APP_OIDC_URL = process.env.REACT_APP_OIDC_URL!
const REACT_APP_OIDC_CLIENT_ID = process.env.REACT_APP_OIDC_CLIENT_ID!
const REACT_APP_REDIRECT_PATH = process.env.REACT_APP_REDIRECT_PATH!
const REACT_APP_AVALIS_REFDATA_URL = process.env.REACT_APP_AVALIS_REFDATA_URL

const appUrl = window.location.origin

export const oidcConfiguration = {
  authority: REACT_APP_OIDC_URL,
  client_id: REACT_APP_OIDC_CLIENT_ID,
  automaticSilentRenew: true,
  loadUserInfo: true,
  response_type: 'code',
  scope: 'openid',
  post_logout_redirect_uri: `${appUrl}`,
  // These paths are handled by react-oidc
  redirect_uri: appUrl + REACT_APP_REDIRECT_PATH,
}

export const configuration = {
  oidcConfiguration,
  refDataUrl: REACT_APP_AVALIS_REFDATA_URL,
}

export default configuration
