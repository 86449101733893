import { useOidcAccessToken } from '@axa-fr/react-oidc'
import { Button, Grid, Icon, Notification } from '@myeh/design-system'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { IDocumentToMigrate } from '../../../model'
import apiUrls from '../../../services/apiUrls'
import Modal from '../../organisms/Modal'
import DocumentsMigrationRow from './DocumentsMigrationRow'

type DocumentsMigrationStatus = 'none' | 'loading' | 'error'

export interface DocumentsMigrationModalProps {
  isOpen: boolean
  onClose: () => void
}

const getDefaultSignature: () => IDocumentToMigrate = () => ({
  signatureId: '',
  bondRequestId: '',
})

const DocumentsMigrationModal = ({ isOpen, onClose }: DocumentsMigrationModalProps) => {
  const { accessToken } = useOidcAccessToken()
  const [signatureRows, setSignatureRows] = useState([getDefaultSignature()])
  const [documentsMigrationStatus, setDocumentsMigrationStatus] = useState<DocumentsMigrationStatus>('none')
  const addTransferLine = () => setSignatureRows([...signatureRows, getDefaultSignature()])
  const submitForm = async () => {
    if (documentsMigrationStatus === 'loading') {
      return
    }
    const toSubmit = signatureRows.filter(({ signatureId, bondRequestId }) => signatureId && bondRequestId)
    setDocumentsMigrationStatus('loading')

    try {
      const res = await fetch(apiUrls.migrateDocuments(), {
        method: 'POST',
        body: JSON.stringify(toSubmit),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      if (!res.ok) {
        setDocumentsMigrationStatus('error')
        console.error('!res.ok', res)
        return
      }
      onClose()
    } catch (err) {
      console.error('catch', err)
      setDocumentsMigrationStatus('error')
    }
  }
  return (
    <Modal
      isOpen={isOpen}
      titleKey="documents-migration-modal-title"
      handleConfirmClick={submitForm}
      setOpenState={state => state || onClose()}
      closeOnConfirm={false}
    >
      <Grid>
        {documentsMigrationStatus === 'error' && (
          <Grid.Row>
            <Notification context="error">
              <FormattedMessage id="documents-migration-error" />
            </Notification>
          </Grid.Row>
        )}
        <form onSubmit={submitForm}>
          {signatureRows.map((document, key) => (
            <DocumentsMigrationRow
              documentToMigrate={document}
              key={`document-row-${key}`}
              onChange={newDocument =>
                setSignatureRows([...signatureRows.slice(0, key), newDocument, ...signatureRows.slice(key + 1)])
              }
              disabled={documentsMigrationStatus === 'loading'}
            />
          ))}
        </form>
        <Grid.Row>
          <Grid.Column size="12">
            <Button onClick={addTransferLine}>
              <Icon name="plus" />
              <FormattedMessage id="documents-migration-modal-add-line" />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Modal>
  )
}

export default DocumentsMigrationModal
