import enMessages from './en_UK.json'
import frMessages from './fr_FR.json'
import { languages } from '../model.js'

const messagesList = {
  en: enMessages,
  fr: frMessages,
}

const localeCodes = {
  en: 'en-EN',
  fr: 'fr-FR',
}

export const getUserLang = (detectedLang: string) => {
  const userLang: languages = 'fr'

  return userLang
}

export function getLocaleCode(language: languages) {
  return localeCodes[language]
}

export function getMessages(language: languages) {
  return messagesList[language]
}
