import React, { useState, useEffect, ReactNode } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormField, FormFieldInputProps } from '@myeh/design-system'

import './Input.scss'

export interface InputProps extends Omit<FormFieldInputProps, 'name' | 'label'> {
  type?: string
  labelKey?: string
  value: string
  changeValue?: (value: string) => void
  inputBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
  isRequired?: boolean
  currency?: string
  placeholder?: string
  defaultError?: string | { key: string; values: Record<string, ReactNode> }
  dataTestid?: string
  validateBeforeFirstBlur?: boolean
  infoText?: string
  maxLength?: number
  maxCharsKey?: string
  leftCharsKey?: string
  name?: string
}

const Input = ({
  type,
  labelKey,
  value,
  changeValue,
  inputBlur,
  isRequired,
  currency,
  defaultError,
  placeholder,
  dataTestid,
  validateBeforeFirstBlur,
  maxLength,
  maxCharsKey,
  leftCharsKey,
  name,
  ...props
}: InputProps) => {
  const [error, setError] = useState<string | { key: string; values: Record<string, ReactNode> } | undefined>()
  const [firstBlur, setFirstBlur] = useState(validateBeforeFirstBlur || false)

  useEffect(() => {
    setError(defaultError)
  }, [defaultError])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (changeValue) {
      changeValue(event.target.value)
    }
  }
  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstBlur(true)
    if (inputBlur) {
      inputBlur(event)
    }
  }

  useEffect(() => {
    if (firstBlur) {
      setError(isRequired && value === '' ? 'error-required' : defaultError)
    }
  }, [firstBlur, defaultError, isRequired, value])

  const intl = useIntl()

  return (
    <FormField.Input
      {...props}
      name={name || labelKey || ''}
      label={
        labelKey
          ? `${isRequired ? '*' : ''}${intl.formatMessage({
              id: labelKey,
            })}`
          : ''
      }
      appearance="outline"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      type={type || 'text'}
      maxLength={maxLength}
      hasError={!!(firstBlur && (error || props.hasError))}
      floatLabel="always"
      data-testid={dataTestid || (props as unknown as { [key: string]: string })['data-testid'] || 'input'}
      css={{}}
    >
      ({currency && <FormField.Suffix>{currency}</FormField.Suffix>}
      {maxLength && (leftCharsKey || maxCharsKey) && (
        <FormField.Hint>
          {leftCharsKey && (
            <>
              <strong>{maxLength - value.length}</strong> <FormattedMessage id={leftCharsKey} tagName="span" />
            </>
          )}
          {maxCharsKey && (
            <>
              <FormattedMessage id={maxCharsKey} tagName="span" /> <strong>{maxLength}</strong>
            </>
          )}
        </FormField.Hint>
      )}
      ) (
      {firstBlur && error && (
        <FormField.Error appearance="text" data-testid="input-error">
          (
          {typeof error === 'object' && error.values ? (
            <FormattedMessage id={error.key} values={error.values} />
          ) : (
            <FormattedMessage id={error as string} />
          )}
          )
        </FormField.Error>
      )}
      )
    </FormField.Input>
  )
}

export default Input
