import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../layout/Header'
import { Button } from '@myeh/design-system'

const ErrorCallback = () => (
  <>
    <Header />
    <main className="main">
      <section className="app-content">
        <div className="d-flex justify-content-center">
          <p>Connexion Refusée</p>
          <Link to="/events">
            <Button className="mb-3">Connexion</Button>
          </Link>
        </div>
      </section>
    </main>
  </>
)

export default ErrorCallback
