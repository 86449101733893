import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  MdPersonOutline,
  MdOutlineDescription,
  MdOutlineErrorOutline,
  MdOutlinePowerSettingsNew,
  MdOutlineCloudOff,
} from 'react-icons/md'

import { useOidc } from '@axa-fr/react-oidc'

import './sidebar.scss'

const Sidebar = () => {
  const { isAuthenticated } = useOidc()
  const [logLink, setLogLink] = useState('/logout')

  useEffect(() => {
    isAuthenticated ? setLogLink('/logout') : setLogLink('/login')
  }, [isAuthenticated])

  return (
    <aside className="sidebar">
      <nav className="sidebar__menu">
        <Link to="/events">
          <MdOutlineErrorOutline size="2em" />
        </Link>
        <Link to="/requests">
          <MdOutlineDescription size="2em" />
        </Link>
        <Link to="/clients">
          <MdPersonOutline size="2em" />
        </Link>
        <Link to="/failed-documents">
          <MdOutlineCloudOff size="2em" />
        </Link>
        <Link to={logLink}>
          <MdOutlinePowerSettingsNew size="2em" />
        </Link>
      </nav>
    </aside>
  )
}

export default Sidebar
