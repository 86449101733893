import React from 'react'
import { Link, Navigate } from 'react-router-dom'
import { useOidc } from '@axa-fr/react-oidc'

import { Button } from '@myeh/design-system'

import Flex from '../layout/Flex'

import './Login.scss'

const Login = () => {
  const { isAuthenticated } = useOidc()

  return isAuthenticated ? (
    <Navigate to="/home" replace />
  ) : (
    <Flex direction="col" valign="center" className="container-logged-out">
      <p>Vous n&apos;êtes pas connecté</p>
      <Link to="/clients">
        <Button className="mb-3">Connexion</Button>
      </Link>
    </Flex>
  )
}

export default Login
