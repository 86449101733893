import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

import { useOidc } from '@axa-fr/react-oidc'

const Home = () => {
  const { isAuthenticated } = useOidc()

  const [urlToRedirect, setUrlToRedirect] = useState('')

  useEffect(() => {
    if (isAuthenticated) {
      setUrlToRedirect('/clients')
    } else {
      setUrlToRedirect('/login')
    }
  }, [isAuthenticated])

  if (urlToRedirect === '') {
    return <></>
  }

  return <Navigate to={urlToRedirect} replace />
}

export default Home
