/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { Dispatch, useEffect, useState, useRef } from 'react'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { useOidcAccessToken } from '@axa-fr/react-oidc'

export const useEventSource = (
  initialUrl: string | null
): {
  message: string | null
  errorEventSource: boolean
  setEventSourceUrl: Dispatch<React.SetStateAction<string | null>>
  eventSource: EventSource | null
} => {
  const source = useRef<EventSource | null>(null)
  const [url, setEventSourceUrl] = useState(initialUrl)
  const [message, setMessage] = useState<string | null>(null)
  const [errorEventSource, setError] = useState(false)
  const { accessToken } = useOidcAccessToken()

  useEffect(() => {
    if (!url) {
      return
    }
    setError(false)
    const evtSource = new EventSourcePolyfill(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    source.current = evtSource

    evtSource.onmessage = (e: any) => {
      setMessage(e.data as string | null)
    }

    evtSource.onerror = (e: any) => {
      console.error('EventSource failed.', e)
      setError(true)
    }

    return () => {
      evtSource.close()
    }
  }, [url, accessToken, setMessage])

  const eventSource = source.current

  return { message, errorEventSource, setEventSourceUrl, eventSource }
}
