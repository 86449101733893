/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { ReactNode, useCallback, useReducer } from 'react'
import { FormattedMessage } from 'react-intl'
import { Icon } from '@myeh/design-system'
import Flex from '../layout/Flex'
import DateInput from '../molecules/DateInput'
import Dropdown from '../molecules/Dropdown'
import Input from '../molecules/Input'
import Table from '../molecules/Table'
import { format } from 'date-fns'
import Pagination from 'react-bootstrap/Pagination'
import { Button, Spinner } from '@myeh/design-system'

import './PagedTable.scss'
import { useState } from 'react'

type Item = string | ReactNode | Item[]
function getItem(item: unknown): Item {
  switch (typeof item) {
    case 'string':
      return item
    case 'boolean':
      return <Icon size="small" name={item ? 'check' : 'close'} />
    case 'number':
      return item.toString()
    case 'object':
      return item instanceof Date
        ? format(item, 'dd/MM/yyyy')
        : React.isValidElement(item)
        ? item
        : Array.isArray(item)
        ? item.map(getItem)
        : ''
    default:
      return ''
  }
}

enum ActionType {
  RESET,
  SET_VALUE,
  SET_TYPE,
  SET_START_DATE,
  SET_END_DATE,
}
type Action =
  | { type: ActionType.RESET }
  | { type: ActionType.SET_VALUE; value: string }
  | { type: ActionType.SET_TYPE; value: string }
  | { type: ActionType.SET_START_DATE; date: Date | null }
  | { type: ActionType.SET_END_DATE; date: Date | null }

interface ReducerState {
  filterType: string | null
  filterValue: string
  startDate: Date | null
  endDate: Date | null
}

const reducer = (state: ReducerState, action: Action) => {
  switch (action.type) {
    case ActionType.RESET:
      return {
        ...state,
        filterValue: '',
        startDate: null,
        endDate: null,
      }
    case ActionType.SET_VALUE:
      return {
        ...state,
        filterValue: action.value,
      }
    case ActionType.SET_TYPE:
      return {
        ...state,
        filterType: action.value,
        filterValue: '',
      }
    case ActionType.SET_START_DATE:
      return {
        ...state,
        startDate: action.date,
      }
    case ActionType.SET_END_DATE:
      return {
        ...state,
        endDate: action.date,
      }
    default:
      throw new Error(`Unhandled type: ${action as string}`)
  }
}

interface PagedTableProps {
  data: any[]
  totalPages: number
  keys: string[]
  filters: string[]
  setParams: React.SetStateAction<any>
  isLoading?: boolean
  dateFilterEnabled: boolean
  dataTestid?: string
}

const PagedTable = ({
  data,
  totalPages,
  keys,
  filters,
  setParams,
  isLoading,
  dateFilterEnabled,
  dataTestid = 'paged-table',
}: PagedTableProps) => {
  const cleanState = {
    filterType: filters.length > 0 ? filters[0] : null,
    filterValue: '',
    startDate: null,
    endDate: null,
  }
  const [state, dispatch] = useReducer(reducer, cleanState)

  const { filterValue, filterType, startDate, endDate } = state

  const [currentPage, setCurrentPage] = useState(1)

  const applyFilters = useCallback(() => {
    const params: any = {
      page: 1,
      startDate: startDate ? format(startDate, 'dd/MM/yyyy') : null,
      endDate: endDate ? format(endDate, 'dd/MM/yyyy') : null,
    }
    if (filterType) {
      params[filterType] = filterValue !== '' ? filterValue : null
    }
    setCurrentPage(1)
    setParams(params)
  }, [filterType, filterValue, startDate, endDate, setParams])

  const resetFilters = useCallback(() => {
    dispatch({ type: ActionType.RESET })
    const params: any = {
      page: 1,
      startDate: null,
      endDate: null,
    }
    if (filterType) {
      params[filterType] = null
    }
    setCurrentPage(1)
    setParams(params)
  }, [dispatch, filterType, setParams])

  const handleSelectChange = (value: string) => {
    dispatch({ type: ActionType.SET_TYPE, value })
  }

  const handleInputChange = (value: string) => {
    dispatch({ type: ActionType.SET_VALUE, value })
  }

  const handleStartDateChange = (date: Date | Date[] | null) => {
    if (Array.isArray(date)) {
      return
    }
    dispatch({ type: ActionType.SET_START_DATE, date })
  }

  const handleEndDateChange = (date: Date | Date[] | null) => {
    if (Array.isArray(date)) {
      return
    }
    dispatch({ type: ActionType.SET_END_DATE, date })
  }

  const handlePaginationClick = (page: number) => {
    setCurrentPage(page)
    setParams((currentParams: Record<string, any>) => ({
      ...currentParams,
      page,
    }))
  }

  const paginatorSize = 5
  const showPaginatorControls = totalPages > paginatorSize
  const sliceStart =
    currentPage < paginatorSize
      ? 0
      : currentPage > totalPages - paginatorSize
      ? totalPages - paginatorSize
      : currentPage - Math.ceil(paginatorSize / 2)

  return (
    <Flex direction="col" className={isLoading ? 'loading' : ''}>
      <Flex direction="row" className="filters flx__row--spaced">
        <Dropdown
          labelKey="filter"
          items={filters.map(filter => ({
            code: filter,
            labelKey: filter,
          }))}
          onChange={handleSelectChange}
          dataTestid={`${dataTestid}-filter-type`}
        />
        <Input
          value={filterValue}
          changeValue={handleInputChange}
          labelKey="information"
          dataTestid={`${dataTestid}-filter-value`}
        />
        {dateFilterEnabled && (
          <>
            <DateInput
              value={startDate}
              onChange={handleStartDateChange}
              format="dd/MM/y"
              locale="fr-FR"
              labelKey="start-date"
              dataTestid={`${dataTestid}-start-date`}
            />
            <DateInput
              value={endDate}
              onChange={handleEndDateChange}
              format="dd/MM/y"
              locale="fr-FR"
              labelKey="end-date"
              dataTestid={`${dataTestid}-end-date`}
            />
          </>
        )}
        <Button onClick={applyFilters} data-testid={`${dataTestid}-apply-filters`}>
          <FormattedMessage id="table-filter-action" />
        </Button>
        <Button onClick={resetFilters} danger data-testid={`${dataTestid}-reset-filters`}>
          <FormattedMessage id="table-filter-reset" />
        </Button>
      </Flex>
      <Table data-testid={dataTestid}>
        <Table.Row head data-testid={`${dataTestid}-head`}>
          {keys.map(key => (
            <Table.Cel head key={key} data-testid={`${dataTestid}-head-${key}`}>
              <FormattedMessage id={key} />
            </Table.Cel>
          ))}
        </Table.Row>
        {data.length > 0 &&
          data.map((item: any, idx: number) => (
            <Table.Row key={idx} data-testid={`${dataTestid}-row-${idx}`}>
              {keys.map(key => {
                return key in item ? (
                  <Table.Cel key={`${idx}${key}`} data-testid={`${dataTestid}-row-${idx}-${key}`}>
                    {getItem(item[key])}
                  </Table.Cel>
                ) : (
                  <Table.Cel key={`${idx}${key}`} data-testid={`${dataTestid}-row-${idx}-${key}`}>
                    -
                  </Table.Cel>
                )
              })}
            </Table.Row>
          ))}
      </Table>
      {isLoading ? (
        <div className="d-flex justify-content-center" data-testid={`${dataTestid}-loader`}>
          <Spinner size="large" />
        </div>
      ) : data.length === 0 ? (
        <div>Nous n&apos;avons aucune data pour votre demande</div>
      ) : null}
      {data.length > 0 && (
        <Pagination style={{ justifyContent: 'center', color: '#0065AE' }} data-testid={`${dataTestid}-pagination`}>
          {showPaginatorControls && (
            <>
              <Pagination.First
                onClick={() => handlePaginationClick(1)}
                disabled={currentPage === 1}
                data-testid={`${dataTestid}-pagination-first`}
              />
              <Pagination.Prev
                onClick={() => handlePaginationClick(currentPage - 1)}
                disabled={currentPage === 1}
                data-testid={`${dataTestid}-pagination-previous`}
              />
              {currentPage >= paginatorSize && <Pagination.Ellipsis />}
            </>
          )}

          {new Array(totalPages)
            .fill('')
            .map((_, idx) => idx + 1)
            .slice(sliceStart, sliceStart + paginatorSize)
            .map(itemPage => (
              <Pagination.Item
                key={itemPage}
                onClick={() => handlePaginationClick(itemPage)}
                active={currentPage === itemPage}
                data-testid={`${dataTestid}-pagination-item-${itemPage}`}
              >
                {itemPage}
              </Pagination.Item>
            ))}
          {showPaginatorControls && (
            <>
              {(currentPage < paginatorSize || currentPage <= totalPages - paginatorSize) && <Pagination.Ellipsis />}
              <Pagination.Next
                onClick={() => handlePaginationClick(currentPage + 1)}
                disabled={currentPage === totalPages}
                data-testid={`${dataTestid}-pagination-next`}
              />
              <Pagination.Last
                onClick={() => handlePaginationClick(totalPages)}
                disabled={currentPage === totalPages}
                data-testid={`${dataTestid}-pagination-last`}
              />
            </>
          )}
        </Pagination>
      )}
    </Flex>
  )
}

export default PagedTable
