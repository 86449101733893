import React from 'react'
import logo from '../../img/az_logo.svg'
import { useOidc, useOidcUser } from '@axa-fr/react-oidc'

import './header.scss'

const Header = () => {
  const { isAuthenticated } = useOidc()
  const { oidcUser } = useOidcUser()

  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Allianz Trade logo" />
      </div>
      <div className="user">
        {isAuthenticated && oidcUser && (
          <span className="name">
            {oidcUser.given_name} {oidcUser.family_name}
          </span>
        )}
      </div>
    </header>
  )
}

export default Header
