import React, { FC } from 'react'

import './table.scss'

interface TableCelProps {
  head?: boolean
}

const TableCel: FC<TableCelProps> = ({ head, children, ...props }) => {
  return head ? <th {...props}>{children}</th> : <td {...props}>{children}</td>
}

interface TableRowProps {
  head?: boolean
}

const TableRow: FC<TableRowProps> = ({ head, children, ...props }) => {
  return head ? (
    <tr className="thead-light" {...props}>
      {children}
    </tr>
  ) : (
    <tr {...props}>{children}</tr>
  )
}

interface TableComposition {
  Row: FC<TableRowProps>
  Cel: FC<TableCelProps>
}

const Table: FC & TableComposition = ({ children, ...props }) => {
  return (
    <table className="table" {...props}>
      <tbody>{children}</tbody>
    </table>
  )
}

Table.Row = TableRow
Table.Cel = TableCel

export default Table
