import { DropDownItem } from '../../components/molecules/Dropdown'
import { addSpacesToString } from './common'

export const formatSwissPhoneNumber = (value: string) => {
  const numbers = value.replace(/\D/g, '')
  const nbs = numbers.startsWith('41') || (numbers.startsWith('4') && numbers.length === 1) ? numbers : `41${numbers}`
  if (nbs.length > 11) {
    return
  }
  return '+' + addSpacesToString(nbs, [2, 4, 7, 9])
}

export const formatFrenchPhoneNumber = (value: string) => {
  const numbers = value.startsWith('+33') ? value.slice(3).replace(/\D/g, '') : value.replace(/\D/g, '')
  const nbs = numbers.startsWith('0') ? numbers : `0${numbers}`
  if (nbs.length > 10) {
    return
  }
  return addSpacesToString(nbs, [2, 4, 6, 8])
}

export const sanitizeFrenchNumber = (phone: string, prefix: string) => {
  const nb = phone.replace(/\D/g, '')
  return `${prefix}${nb.startsWith('0') ? nb.slice(1) : nb}`
}

export const frenchInternationalPrefixes: DropDownItem[] = [
  { code: '+33', labelKey: '+33', dontTranslate: true },
  { code: '+262', labelKey: '+262', dontTranslate: true },
  { code: '+689', labelKey: '+689', dontTranslate: true },
]
