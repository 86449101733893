import React from 'react'
import { Navigate } from 'react-router-dom'
import { useOidc } from '@axa-fr/react-oidc'
import { Spinner } from '@myeh/design-system'
import Header from '../layout/Header'

const Loading = () => {
  const { isAuthenticated } = useOidc()

  return isAuthenticated ? (
    <Navigate to="/home" replace />
  ) : (
    <>
      <Header />
      <main className="main">
        <section className="app-content">
          <div className="d-flex justify-content-center">
            <Spinner size="large" />
          </div>
        </section>
      </main>
    </>
  )
}

export default Loading
