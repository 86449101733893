export const baseApiUrl = process.env.REACT_APP_API_URL || '/bo'

export const objectToQueryString = (params: Record<string, any>) =>
  Object.entries(params)
    .filter(([k, v]) => v !== null)
    .reduce((acc, [k, v], idx, arr) => {
      return `${idx === 0 ? '?' : acc}${k}=${v as string}${idx === arr.length - 1 ? '' : '&'}`
    }, '')

const apiUrls = {
  getUser: (userId: string) => `${baseApiUrl}/users/${userId}`,
  getUsers: (params: Record<string, any>) => `${baseApiUrl}/users/${objectToQueryString(params)}`,
  prepareUsersFile: () => `${baseApiUrl}/users/prepare`,
  rawUsersFile: () => `${baseApiUrl}/users/raw`,
  getEvents: (params: Record<string, any>) => `${baseApiUrl}/events/${objectToQueryString(params)}`,
  rawEventsFile: () => `${baseApiUrl}/events/raw`,
  getRequests: (params: Record<string, any>) => `${baseApiUrl}/requests/${objectToQueryString(params)}`,
  prepareRequestsFile: () => `${baseApiUrl}/requests/prepare`,
  rawRequestsFile: () => `${baseApiUrl}/requests/raw`,
  getDocuments: (params: Record<string, any>) => `${baseApiUrl}/documents/${objectToQueryString(params)}`,
  retryDocumentsArchiving: () => `${baseApiUrl}/documents/retry-all-unarchived`,
  migrateDocuments: () => `${baseApiUrl}/documents/signatures`,
  getCompany: (companyId: string) =>
    `${baseApiUrl}/account/search-company?countryCode=FR&idTypeCode=SIREN&idValue=${companyId}`,
  createAccount: () => `${baseApiUrl}/account`,
}

export default apiUrls
