import React, { useEffect } from 'react'
import { Icon } from '@myeh/design-system'
import { format } from 'date-fns'
import { useDataApi } from '../../hooks/apiData'
import { formatFrenchSiren } from '../../services/format/company'
import { formatFrenchPhoneNumber } from '../../services/format/phone'
import { Spinner } from '@myeh/design-system'
import Flex from '../layout/Flex'
import { FormattedMessage } from 'react-intl'
import apiUrls from '../../services/apiUrls'

interface UserResponse {
  creationDateTime: string
  parentCompanyId: string
  parentCompanyName: string
  parentCompanyEHId: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  contactId: string
}

interface UserDetailProps {
  userId: string
}

const UserDetail = ({ userId }: UserDetailProps) => {
  const [{ data, isLoading, isError }, setUrl] = useDataApi<UserResponse>(null, {})

  const {
    creationDateTime,
    parentCompanyName,
    parentCompanyId,
    parentCompanyEHId,
    firstName,
    lastName,
    email,
    phoneNumber,
    contactId,
  } = data

  useEffect(() => {
    setUrl(apiUrls.getUser(userId))
  }, [userId, setUrl])

  return (
    <Flex direction="col" valign="center" halign="center">
      {isLoading ? (
        <Spinner />
      ) : isError ? (
        <div>
          <FormattedMessage id="data-error" />
        </div>
      ) : (
        <>
          <div className="text-blue">
            <Icon name="product-city" size="large" />
          </div>
          <h3 className="title text-blue">{parentCompanyName}</h3>
          <div className="subheading mb-3">
            <FormattedMessage id="client-information" />
            <hr />
          </div>
          {[
            {
              key: 'subscribe-date',
              value: creationDateTime && format(new Date(creationDateTime), 'dd/MM/yyyy'),
            },
            { key: 'company', value: parentCompanyName },
            {
              key: 'siren',
              value: parentCompanyId && formatFrenchSiren(parentCompanyId),
            },
            {
              key: 'phoneNumber',
              value: phoneNumber && formatFrenchPhoneNumber(phoneNumber),
            },
            { key: 'email', value: email },
            { key: 'parentCompanyId', value: parentCompanyEHId },
            { key: 'sales-force-id', value: contactId },
            {
              key: 'legalRepresentative',
              value: `${lastName} ${firstName}`,
            },
          ].map(({ key, value }) => (
            <div key={key} className="body1-light">
              <span className="bold">
                <FormattedMessage id={key} /> :{' '}
              </span>
              {value}
            </div>
          ))}
        </>
      )}
    </Flex>
  )
}

export default UserDetail
