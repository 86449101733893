import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Icon } from '@myeh/design-system'
import { useDataApi } from '../../hooks/apiData'
import { useDownloadList } from '../../hooks/useDownloadList'
import Flex from '../layout/Flex'
import PagedTable from '../organisms/PagedTable'
import { useEventSource } from '../../hooks/useEventSource'
import apiUrls from '../../services/apiUrls'

const keys = [
  'creationDateTime',
  'parentCompanyId',
  'parentCompanyEHId',
  'legalRepresentative',
  'firstName',
  'lastName',
  'email',
  'phoneNumber',
  'completedBondCount',
]

const filters = ['parentCompanyId', 'email']

interface UserData {
  creationDateTime: string
  parentCompanyId: string
  parentCompanyEHId: string
  contactId: string
  legalRepresentative: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  completedBondCount: string
  lastStatus: string
}

interface UsersApiResponse {
  list: UserData[]
  totalPages: string
  totalItems: string
}

const requiredParams = {
  page: 1,
  pageSize: 10,
}

enum CSV_STATE {
  NOT_STARTED,
  PREPARING,
  PREPARE_COMPLETE,
}

const Clients = () => {
  const [{ data, isLoading, isError }, setUrl] = useDataApi<UsersApiResponse>(null, { list: [] })
  const [{ isDownloading, isDownloadError }, setDownloadListUrl] = useDownloadList(null, 'mcel_clients.csv')
  const [parsedData, setParsedData] = useState<any[]>([])
  const [errorParsing, setErrorParsing] = useState(false)
  const [params, setParams] = useState(requiredParams)
  const { message, setEventSourceUrl, eventSource } = useEventSource(null)
  const [csv, setCsv] = useState(CSV_STATE.NOT_STARTED)

  useEffect(() => {
    setErrorParsing(false)
    try {
      setParsedData(
        data.list.map(item => ({
          ...item,
          legalRepresentative: Boolean(item.legalRepresentative),
          creationDateTime: new Date(item.creationDateTime),
          date: new Date(item.creationDateTime),
        }))
      )
    } catch (e) {
      setParsedData([])
      setErrorParsing(true)
      console.error(e)
    }
  }, [data, setParsedData])

  useEffect(() => {
    setParsedData([])
    setUrl(
      apiUrls.getUsers({
        ...requiredParams,
        ...params,
      })
    )
  }, [params, setUrl, setParsedData])

  useEffect(() => {
    if (!message) {
      return
    }
    if (message.includes('COMPLETED')) {
      setCsv(CSV_STATE.PREPARE_COMPLETE)
      eventSource?.close()
      setEventSourceUrl(null)
    }
    if (message.includes('ERROR')) {
      setCsv(CSV_STATE.NOT_STARTED)
      eventSource?.close()
      setEventSourceUrl(null)
    }
  }, [message, eventSource, setCsv, setEventSourceUrl])

  const handlePrepareButton = useCallback(() => {
    setEventSourceUrl(apiUrls.prepareUsersFile())
    setCsv(CSV_STATE.PREPARING)
  }, [setCsv, setEventSourceUrl])

  const handleDownloadButton = useCallback(() => {
    setDownloadListUrl(apiUrls.rawUsersFile())
  }, [setDownloadListUrl])

  return (
    <Flex direction="col">
      <Flex direction="row" valign="center" halign="space-between">
        <h1 className="title text-left mb-3">
          <FormattedMessage id="client-list" />
        </h1>
        {csv === CSV_STATE.NOT_STARTED ? (
          <Button data-testid="download-file-button" onClick={handlePrepareButton}>
            <FormattedMessage id="prepare" />
          </Button>
        ) : csv === CSV_STATE.PREPARING ? (
          <Button data-testid="download-file-button" onClick={() => {}} disabled={true}>
            <FormattedMessage id="preparing" />
          </Button>
        ) : (
          <Button data-testid="download-file-button" onClick={handleDownloadButton} disabled={isDownloading}>
            {isDownloading ? (
              <FormattedMessage id="downloading" />
            ) : (
              <>
                <span className="mr-2">
                  <FormattedMessage id="download" />
                </span>
                <span className="align-self-center">
                  <Icon name="download" />
                </span>
              </>
            )}
          </Button>
        )}
      </Flex>
      {isDownloadError && (
        <div>
          <FormattedMessage id="download-error" />
        </div>
      )}
      <PagedTable
        data={parsedData}
        keys={keys}
        filters={filters}
        totalPages={Number(data.totalPages)}
        setParams={setParams}
        isLoading={isLoading}
        dateFilterEnabled={false}
      />
      {(isError || errorParsing) && (
        <div>
          <FormattedMessage id="data-error" />
        </div>
      )}
    </Flex>
  )
}

export default Clients
