import React, { Dispatch, useEffect, useState } from 'react'
import { useOidcAccessToken } from '@axa-fr/react-oidc'

export const useDownloadList = (
  initialUrl: string | null,
  fileName: string | null
): [{ isDownloading: boolean; isDownloadError: boolean }, Dispatch<React.SetStateAction<string | null>>] => {
  const [isDownloading, setIsDownloading] = useState(false)
  const [isDownloadError, setIsDownloadError] = useState(false)
  const [url, setUrl] = useState<string | null>(initialUrl)
  const { accessToken } = useOidcAccessToken()

  useEffect(() => {
    setIsDownloadError(false)
    const fetchList = async () => {
      if (!url) {
        return
      }
      try {
        setIsDownloading(true)
        const headers = new Headers()
        headers.set('Authorization', `Bearer ${accessToken}`)
        const response = await fetch(url, {
          headers,
        })

        if (!response.ok) {
          setIsDownloading(false)
          setIsDownloadError(true)
          return
        }

        const text = await response.text()
        const encodedCsv = encodeURIComponent(text)
        const encodedUrl = 'data:text/csv;charset=utf-8,' + encodedCsv
        const link = document.createElement('a')
        link.setAttribute('href', encodedUrl)
        link.setAttribute('download', fileName ? fileName : 'data.csv')
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()

        setIsDownloading(false)
        setUrl(null)
      } catch (e: any) {
        console.error(e)
        console.error(e.response)
        setIsDownloading(false)
        setIsDownloadError(true)
      }
    }
    fetchList()
  }, [url, accessToken, fileName])

  return [{ isDownloading, isDownloadError }, setUrl]
}
