import React, { useEffect, useState, useCallback } from 'react'
import { format } from 'date-fns'
import { FormattedMessage } from 'react-intl'
import { Button, Icon } from '@myeh/design-system'
import { useDataApi } from '../../hooks/apiData'
import { useDownloadList } from '../../hooks/useDownloadList'
import Flex from '../layout/Flex'
import PagedTable from '../organisms/PagedTable'
import Modal from '../organisms/Modal'
import UserDetail from '../organisms/UserDetail'
import apiUrls from '../../services/apiUrls'
import { useAvalisRefData } from '../../hooks/useAvalisRefData'

const keys = ['timestamp', 'username', 'siren', 'phone', 'email', 'origin', 'code', 'message', 'status', 'detail']

const filters = ['username', 'siren', 'phone', 'email', 'origin', 'code', 'message', 'status']

interface EventData {
  id: string
  code: string
  message: string
  status: string
  origin: string
  username: string
  phone: string
  email: string
  siren: string
  timestamp: string
}

interface EventsApiResponse {
  list: EventData[]
  totalPages: string
  totalItems: string
}

const requiredParams = {
  page: 1,
  pageSize: 20,
}

const Events = () => {
  const [{ data, isLoading, isError }, setUrl] = useDataApi<EventsApiResponse>(null, { list: [] })
  const [{ isDownloading, isDownloadError }, setDownloadListUrl] = useDownloadList(null, 'mcel_evenements.csv')
  const [parsedData, setParsedData] = useState<any[]>([])
  const [params, setParams] = useState<Record<string, unknown>>(requiredParams)
  const [errorParsing, setErrorParsing] = useState(false)
  const [modal, setModal] = useState(false)
  const [detail, setDetail] = useState('')
  const { refTable } = useAvalisRefData()

  const getDetailCodeFromMessage = (message: string | null): string => {
    const avalisPattern = 'Quotation result: '
    if (!message || !message.includes(avalisPattern)) {
      return '-'
    }
    return message.replace(avalisPattern, '').toLowerCase()
  }

  const messageParser = useCallback(
    (origin: string, message: string) => {
      const pattern = 'Quotation result: '
      const avalisRefTranslation = refTable.find(t => t.code.toLowerCase() === getDetailCodeFromMessage(message))?.label
      return origin !== 'AVALIS' || !message.includes(pattern) ? message : avalisRefTranslation || message
    },
    [refTable]
  )

  const handleMoreInfoBtn = useCallback(
    (id: string) => {
      setModal(true)
      setDetail(id)
    },
    [setModal]
  )

  useEffect(() => {
    setErrorParsing(false)
    try {
      setParsedData(
        data.list.map(item => {
          const date = new Date(item.timestamp)
          return {
            ...item,
            code: `${item.code} (${getDetailCodeFromMessage(item.message).toUpperCase()})`,
            timestamp: format(date, 'dd/MM/yyyy HH:mm').replace(/\s/, '\n'),
            date,
            detail: item.email ? (
              <span onClick={() => handleMoreInfoBtn(item.email)} className="text-blue pointer">
                <Icon name="password-show" size="small" />
              </span>
            ) : null,
            message: messageParser(item.origin, item.message),
          }
        })
      )
    } catch (e) {
      setParsedData([])
      setErrorParsing(true)
      console.error(e)
    }
  }, [data, setParsedData, handleMoreInfoBtn, messageParser, refTable])

  useEffect(() => {
    setParsedData([])
    setUrl(
      apiUrls.getEvents({
        ...requiredParams,
        ...params,
      })
    )
  }, [params, setUrl, setParsedData])

  const handleDownloadButton = useCallback(() => {
    if (isDownloading) {
      return
    }
    setDownloadListUrl(apiUrls.rawEventsFile())
  }, [isDownloading, setDownloadListUrl])

  return (
    <Flex direction="col">
      <Flex direction="row" valign="center" halign="space-between">
        <h1 className="title text-left mb-3">
          <FormattedMessage id="event-list" />
        </h1>
        <Button onClick={handleDownloadButton} disabled={isDownloading}>
          {isDownloading ? (
            <FormattedMessage id="downloading" />
          ) : (
            <>
              <span className="mr-2">
                <FormattedMessage id="download" />
              </span>
              <span className="align-self-center">
                <Icon name="download" />
              </span>
            </>
          )}
        </Button>
      </Flex>
      {isDownloadError && (
        <div>
          <FormattedMessage id="download-error" />
        </div>
      )}
      <PagedTable
        data={parsedData}
        keys={keys}
        filters={filters}
        totalPages={Number(data.totalPages)}
        setParams={setParams}
        isLoading={isLoading}
        dateFilterEnabled={true}
      />
      {(isError || errorParsing) && (
        <div>
          <FormattedMessage id="data-error" />
        </div>
      )}
      <Modal isOpen={modal} setOpenState={setModal} titleKey="" forceStayOpen={true}>
        <UserDetail userId={detail} />
      </Modal>
    </Flex>
  )
}

export default Events
