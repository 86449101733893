/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { Dispatch, useEffect, useState } from 'react'
import { useOidcAccessToken } from '@axa-fr/react-oidc'

export const useDataApi = <T,>(
  initialUrl: string | null,
  initialData: any
): [{ data: T; isLoading: boolean; isError: boolean }, Dispatch<React.SetStateAction<string | null>>] => {
  const [data, setData] = useState(initialData)
  const [url, setUrl] = useState(initialUrl)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const { accessToken } = useOidcAccessToken()

  useEffect(() => {
    let cancelled = false
    const fetchData = async () => {
      if (!url) {
        return
      }
      setIsError(false)
      setIsLoading(true)
      try {
        const headers = new Headers()
        headers.set('Authorization', `Bearer ${accessToken}`)
        const response = await fetch(encodeURI(url), {
          headers,
        })
        if (response.status === 200) {
          const json = await response.json()
          if (!cancelled) {
            setData(json)
          }
        } else {
          setIsError(true)
        }
      } catch (e) {
        setIsError(true)
      }
      setIsLoading(false)
    }
    fetchData()

    return () => {
      cancelled = true
    }
  }, [url, accessToken])

  return [{ data, isLoading, isError }, setUrl]
}
