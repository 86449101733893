import React, { ReactNode } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormField, FormFieldDateProps, FormFieldInputProps } from '@myeh/design-system'
import * as locales from 'date-fns/locale'

interface DateInputProps extends FormFieldDateProps {
  labelKey?: string
  isRequired?: boolean
  defaultError?: string | { key: string; values?: Record<string, ReactNode> }
  locale?: string | Locale
  onChange?: (date: Date | Date[] | null) => void
  dataTestid?: string
}

const DateInput = (props: DateInputProps) => {
  const { labelKey, isRequired, defaultError, locale: primaryLocale, name, dataTestid, ...remainingProps } = props

  let locale = primaryLocale
  if (typeof locale === 'string') {
    if (Object.hasOwnProperty.call(locales, locale)) {
      locale = (locales as { [key: string]: Locale })[locale]
    } else {
      locale = locales.enGB
    }
  }

  const intl = useIntl()

  return (
    <>
      <FormField.DateField
        {...remainingProps}
        label={
          labelKey &&
          `${isRequired ? '*' : ''}${intl.formatMessage({
            id: labelKey,
          })}`
        }
        hasError={!!defaultError}
        appearance="outline"
        locale={locale}
        name={(name as string) || ''}
        datePickerProps={{
          'data-testid': `${dataTestid ? `${dataTestid}-` : ''}date-picker`,
        }}
        inputProps={
          {
            'data-testid': `${dataTestid ? `${dataTestid}-` : ''}input`,
          } as unknown as FormFieldInputProps
        }
      />
      {defaultError && (
        <FormField.Error>
          (
          {typeof defaultError === 'object' && defaultError.values ? (
            <FormattedMessage id={defaultError.key} values={defaultError.values} />
          ) : (
            <FormattedMessage id={defaultError as string} />
          )}
          )
        </FormField.Error>
      )}
    </>
  )
}

export default DateInput
