import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Flex from '../layout/Flex'
import PagedTable from '../organisms/PagedTable'
import { useDataApi } from '../../hooks/apiData'
import apiUrls from '../../services/apiUrls'
import { Button, Icon } from '@myeh/design-system'
import { useOidcAccessToken } from '@axa-fr/react-oidc'
import DocumentsMigrationModal from './DocumentsMigrationModal'

const keys = ['dateCreation', 'documentId', 'bondRequestId', 'signatureId', 'type', 'status', 'error', 'dateLastRetry']

const filters = ['documentId', 'bondRequestId', 'signatureId', 'type', 'error', 'status']

const requiredParams = {
  page: 1,
  pageSize: 20,
}

interface RequestDocument {
  id: string
  bondRequestId: string
  signatureId: string
  documentId: string
  type: string
  businessUnitCode: string
  status: string
  error: string
  dateCreation: string
  dateLastRetry: string
}

interface RequestDocumentsResponse {
  list: RequestDocument[]
  totalPages: number
  totalItems: number
}

type RetryStatus = 'none' | 'loading' | 'error' | 'success'

const FailedDocuments = () => {
  const [{ data, isLoading, isError }, setUrl] = useDataApi<RequestDocumentsResponse>(null, { list: [] })

  const [parsedData, setParsedData] = useState<any[]>([])

  const [retryStatus, setRetryStatus] = useState<RetryStatus>('none')

  const [errorParsing, setErrorParsing] = useState(false)

  const [showDocumentsMigrationModal, setShowDocumentsMigrationModal] = useState(false)

  const [params, setParams] = useState<Record<string, any>>(requiredParams)

  const { accessToken } = useOidcAccessToken()

  useEffect(() => {
    setErrorParsing(false)
    try {
      setParsedData(
        data.list.map(item => ({
          ...item,
          dateCreation: new Date(item.dateCreation),
          dateLastRetry: new Date(item.dateLastRetry),
        }))
      )
    } catch (e) {
      setParsedData([])
      setErrorParsing(true)
      console.error(e)
    }
  }, [data, setParsedData])

  useEffect(() => {
    setParsedData([])
    setUrl(
      apiUrls.getDocuments({
        ...requiredParams,
        ...params,
      })
    )
  }, [params, setUrl, setParsedData])

  const handleDocumentsMigration = () => setShowDocumentsMigrationModal(true)

  const handleRetryButtonClick = async () => {
    setRetryStatus('loading')

    try {
      const res = await fetch(apiUrls.retryDocumentsArchiving(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })

      if (!res.ok) {
        setRetryStatus('error')
        console.error('Documents archiving retry failed', res)

        return
      }

      setRetryStatus('success')
    } catch (err) {
      setRetryStatus('error')
      console.error('Documents archiving retry fetch failed', err)
    }
  }

  return (
    <Flex direction="col">
      <Flex direction="row" valign="center" halign="space-between">
        <h1 className="title text-left mb-3">
          <FormattedMessage id="failed-documents-list" />
        </h1>
        <Button onClick={handleDocumentsMigration}>
          <Icon name="export-and-share" />
          <FormattedMessage id="documents-migration" />
        </Button>
        <Button disabled={retryStatus !== 'none'} danger={retryStatus === 'error'} onClick={handleRetryButtonClick}>
          {retryStatus === 'none' ? (
            <>
              <Icon name="refresh" />
              <FormattedMessage id="retry-documents-archiving" />
            </>
          ) : retryStatus === 'loading' ? (
            <>
              <Icon name="refresh" />
              <FormattedMessage id="retry-documents-archiving-loading" />
            </>
          ) : retryStatus === 'error' ? (
            <>
              <Icon name="exclamation-circle" />
              <FormattedMessage id="retry-documents-archiving-error" />
            </>
          ) : (
            <>
              <Icon name="check-circle" />
              <FormattedMessage id="retry-documents-archiving-success" />
            </>
          )}
        </Button>
      </Flex>
      <PagedTable
        data={parsedData}
        keys={keys}
        filters={filters}
        totalPages={data.totalPages}
        isLoading={isLoading}
        dateFilterEnabled={true}
        setParams={setParams}
      />
      {(isError || errorParsing) && (
        <div>
          <FormattedMessage id="data-error" />
        </div>
      )}
      <DocumentsMigrationModal
        isOpen={showDocumentsMigrationModal}
        onClose={() => setShowDocumentsMigrationModal(false)}
      />
    </Flex>
  )
}

export default FailedDocuments
