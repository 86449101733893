import React, { Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Button, Modal as DESYModal } from '@myeh/design-system'

import './Modal.scss'

interface ModalProps {
  isOpen: boolean
  setOpenState?: Dispatch<SetStateAction<boolean>>
  titleKey: string
  textKey?: string
  confirmLabelKey?: string
  handleConfirmClick?: () => void
  onModalClose?: () => void
  forceStayOpen?: boolean
  style?: Record<string, any>
  closeButtonLabelKey?: string
  closeOnConfirm?: boolean
}

const Modal: FunctionComponent<ModalProps> = ({
  isOpen,
  setOpenState,
  titleKey,
  textKey,
  confirmLabelKey,
  handleConfirmClick,
  onModalClose,
  forceStayOpen,
  children,
  style = {},
  closeButtonLabelKey = 'modal-close-dialog',
  closeOnConfirm = true,
}) => {
  const closeModal = useCallback(() => {
    if (setOpenState) {
      setOpenState(false)
    }
  }, [setOpenState])

  const handleConfirmButtonClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      if (handleConfirmClick) {
        handleConfirmClick()
      }
      if (closeOnConfirm) {
        closeModal()
      }
    },
    [handleConfirmClick, closeModal, closeOnConfirm]
  )

  const content = children ? (
    children
  ) : (
    <p>
      <div data-testid="modal__text">{textKey && <FormattedMessage id={textKey} />}</div>
    </p>
  )

  useEffect(() => {
    return () => {
      if (onModalClose) {
        onModalClose()
      }
    }
  }, [onModalClose])

  const intl = useIntl()

  if (!isOpen) {
    return null
  }

  return (
    <DESYModal
      css={{}}
      data-testid="modal"
      open={isOpen}
      onClose={onModalClose}
      hideOnClickOutside={!forceStayOpen}
      onOpenStatusChange={setOpenState}
      size="auto"
      closeButtonLabel={intl.formatMessage({
        id: closeButtonLabelKey,
      })}
      style={style}
    >
      <DESYModal.Content data-testid="modal__content" className="modal_content">
        {titleKey !== '' && (
          <h3 data-testid="modal__title" className="modal__title bold title">
            <FormattedMessage id={titleKey} />
          </h3>
        )}
        {content}
      </DESYModal.Content>
      {handleConfirmClick && (
        <DESYModal.Actions>
          <Button data-testid="modal__confirm" onClick={handleConfirmButtonClick}>
            {confirmLabelKey ? <FormattedMessage id={confirmLabelKey} /> : 'OK'}
          </Button>
        </DESYModal.Actions>
      )}
    </DESYModal>
  )
}

export default Modal
