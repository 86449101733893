import { useOidcAccessToken } from '@axa-fr/react-oidc'
import { useEffect, useState } from 'react'
import configuration from '../configuration'

interface AvalisRefDataElement {
  code: string
  label: string
}

export const useAvalisRefData = (url = configuration.refDataUrl): { refTable: Array<AvalisRefDataElement> } => {
  const { accessToken } = useOidcAccessToken()
  const [refDataTable, setRefDataTable] = useState<Array<AvalisRefDataElement>>([])

  useEffect(() => {
    const getRefDataTable = async () => {
      const headers = new Headers()
      headers.set('Authorization', `Bearer ${accessToken}`)
      const response = await fetch(url || '', {
        headers,
      })
      const content: { refData: AvalisRefDataElement[] } = (await response.json()) as {
        refData: AvalisRefDataElement[]
      }
      setRefDataTable(content?.refData)
    }
    getRefDataTable()
  }, [accessToken, url])

  return { refTable: refDataTable }
}
