import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useOidc } from '@axa-fr/react-oidc'
import Flex from '../layout/Flex'

const Logout = () => {
  const { logout } = useOidc()

  useEffect(() => {
    logout()
  }, [logout])

  return (
    <Flex direction="col" valign="center" halign="center">
      <FormattedMessage id="user-disconnected" />
    </Flex>
  )
}

export default Logout
