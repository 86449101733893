import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Icon } from '@myeh/design-system'
import { useDataApi } from '../../hooks/apiData'
import Flex from '../layout/Flex'
import PagedTable from '../organisms/PagedTable'
import Modal from '../organisms/Modal'
import UserDetail from '../organisms/UserDetail'
import { useDownloadList } from '../../hooks/useDownloadList'
import { useEventSource } from '../../hooks/useEventSource'
import apiUrls from '../../services/apiUrls'

const keys = [
  'bondQuotationRequestId',
  'bondRequestId',
  'creationDateTime',
  'requestorUserId',
  'beneficiaryCompanyId',
  'bondType',
  'marketValue',
  'bondAmount',
  'grossPremiumAmount',
  'startDate',
  'endDate',
  'duration',
  'status',
  'detail',
]

const filters = ['requestorUserId', 'companyId']

export type BondType =
  | ''
  | 'BID'
  | 'PERFORMANCE'
  | 'PERSONAL_DEPOSIT'
  | 'GUARANTEE_FIRST_REQUEST'
  | 'MAIN_COMPANY'
  | 'SUBCONTRACTOR'
  | 'SUBCONTRACTOR_PAYMENT_GUARANTEE'
  | 'JOINT_SURETY'
  | 'INVALID'

export type MCELRequestStatus =
  | 'ACCEPTED'
  | 'FILLED'
  | 'PREPAID'
  | 'PENDING_SIGNATURE'
  | 'COMPLETED'
  | 'EXPIRED'
  | 'REJECTED'
  | 'FACILITY_AMOUNT_EXCEEDED'
  | 'GRADE_UNAVAILABLE'
  | 'GRADE_KO'

interface RequestData {
  bondQuotationRequestId: string
  bondRequestId: string
  creationDateTime: string
  requestorUserId: string
  beneficiaryCompanyId: string
  bondType: BondType
  marketValue: string
  bondAmount: string
  grossPremiumAmount: string
  startDate: string
  endDate: string
  duration: string
  status: MCELRequestStatus
  contactId: string
  parentCompanyId: string
}

interface RequestApiResponse {
  list: RequestData[]
  totalPages: string
  totalItems: string
}

const requiredParams = {
  page: 1,
  pageSize: 20,
}

const euroFormatter = new Intl.NumberFormat('fr', {
  style: 'currency',
  currency: 'EUR',
})

enum CSV_STATE {
  NOT_STARTED,
  PREPARING,
  PREPARE_COMPLETE,
}

const Requests = () => {
  const [{ data, isLoading, isError }, setUrl] = useDataApi<RequestApiResponse>(null, { list: [] })
  const [parsedData, setParsedData] = useState<any[]>([])
  const [params, setParams] = useState<Record<string, any>>(requiredParams)
  const [errorParsing, setErrorParsing] = useState(false)
  const [modal, setModal] = useState(false)
  const [detail, setDetail] = useState('')
  const intl = useIntl()
  const { message, setEventSourceUrl, eventSource } = useEventSource(null)
  const [csv, setCsv] = useState(CSV_STATE.NOT_STARTED)

  const [{ isDownloading, isDownloadError }, setDownloadListUrl] = useDownloadList(null, 'mcel_requests.csv')

  const handleMoreInfoBtn = useCallback(
    (id: string) => {
      setModal(true)
      setDetail(id)
    },
    [setModal]
  )

  useEffect(() => {
    setErrorParsing(false)
    try {
      setParsedData(
        data.list
          .filter(item => Boolean(item.bondType))
          .map(item => ({
            ...item,
            creationDateTime: new Date(item.creationDateTime),
            bondType: intl.formatMessage({
              id: item.bondType.toLowerCase(),
            }),
            status: intl.formatMessage({
              id: item.status.toLowerCase().replace(/_/g, ' '),
            }),
            marketValue: euroFormatter.format(Number(item.marketValue)),
            bondAmount: euroFormatter.format(Number(item.bondAmount)),
            grossPremiumAmount: euroFormatter.format(Number(item.grossPremiumAmount)),
            date: new Date(item.creationDateTime),
            detail: item.requestorUserId ? (
              <span onClick={() => handleMoreInfoBtn(item.requestorUserId)} className="text-blue pointer">
                <Icon name="password-show" size="small" />
              </span>
            ) : null,
          }))
      )
    } catch (e) {
      setParsedData([])
      setErrorParsing(true)
      console.error(e)
    }
  }, [data, setParsedData, intl, handleMoreInfoBtn])

  useEffect(() => {
    setParsedData([])
    setUrl(
      apiUrls.getRequests({
        ...requiredParams,
        ...params,
      })
    )
  }, [params, setUrl, setParsedData])

  useEffect(() => {
    if (!message) {
      return
    }
    if (message.includes('COMPLETED')) {
      setCsv(CSV_STATE.PREPARE_COMPLETE)
      eventSource?.close()
      setEventSourceUrl(null)
    }
    if (message.includes('ERROR')) {
      setCsv(CSV_STATE.NOT_STARTED)
      eventSource?.close()
      setEventSourceUrl(null)
    }
  }, [message, eventSource, setCsv, setEventSourceUrl])

  const handlePrepareButton = useCallback(() => {
    setEventSourceUrl(apiUrls.prepareRequestsFile())
    setCsv(CSV_STATE.PREPARING)
  }, [setCsv, setEventSourceUrl])

  const handleDownloadButton = useCallback(() => {
    setDownloadListUrl(apiUrls.rawRequestsFile())
  }, [setDownloadListUrl])

  return (
    <Flex direction="col">
      <Flex direction="row" valign="center" halign="space-between">
        <h1 className="title text-left mb-3">
          <FormattedMessage id="request-list" />
        </h1>
        {csv === CSV_STATE.NOT_STARTED ? (
          <Button onClick={handlePrepareButton}>
            <FormattedMessage id="prepare" />
          </Button>
        ) : csv === CSV_STATE.PREPARING ? (
          <Button onClick={() => {}} disabled={true}>
            <FormattedMessage id="preparing" />
          </Button>
        ) : (
          <Button onClick={handleDownloadButton} disabled={isDownloading}>
            {isDownloading ? (
              <FormattedMessage id="downloading" />
            ) : (
              <>
                <span className="mr-2">
                  <FormattedMessage id="download" />
                </span>
                <span className="align-self-center">
                  <Icon name="download" />
                </span>
              </>
            )}
          </Button>
        )}
      </Flex>
      {isDownloadError && (
        <div>
          <FormattedMessage id="download-error" />
        </div>
      )}
      <PagedTable
        data={parsedData}
        keys={keys}
        filters={filters}
        totalPages={Number(data.totalPages)}
        setParams={setParams}
        isLoading={isLoading}
        dateFilterEnabled={false}
      />
      {(isError || errorParsing) && (
        <div>
          <FormattedMessage id="data-error" />
        </div>
      )}
      <Modal isOpen={modal} setOpenState={setModal} titleKey="" forceStayOpen={true}>
        <UserDetail userId={detail} />
      </Modal>
    </Flex>
  )
}

export default Requests
