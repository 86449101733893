import React, { FC } from 'react'

import './flex.scss'

interface FlexProps {
  direction: 'row' | 'col'
  halign?: 'center' | 'start' | 'end' | 'space-between'
  valign?: 'center' | 'start' | 'end'
  className?: string
}

const Flex: FC<FlexProps> = ({ direction, halign, valign, children, className }) => {
  const halignPrefix = direction === 'row' ? 'just-' : 'algn-'
  const valignPrefix = direction === 'col' ? 'just-' : 'algn-'
  return (
    <div
      className={`flx
            flx__${direction}
            ${halign ? halignPrefix + halign : ''}
            ${valign ? valignPrefix + valign : ''} ${className || ''}`}
    >
      {children}
    </div>
  )
}

export default Flex
