import React from 'react'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'

import { OidcProvider, OidcSecure } from '@axa-fr/react-oidc'
import { ThemeProvider } from '@myeh/design-system'

import '../node_modules/@myeh/design-system/dist/style.css'
import '../node_modules/@myeh/design-system/dist/fonts.css'
import '../node_modules/@myeh/design-system/dist/icons/style.css'

import Header from './components/layout/Header'
import Login from './components/pages/Login'
import Home from './components/pages/Home'
import ErrorCallback from './components/pages/ErrorCallback'
import Loading from './components/pages/Loading'
import Sidebar from './components/layout/Sidebar'
import Clients from './components/pages/Clients'
import Events from './components/pages/Events'
import Requests from './components/pages/Requests'
import FailedDocuments from './components/pages/FailedDocuments'
import Logout from './components/pages/Logout'
import { LanguageContextProvider } from './components/context/LanguageContextProvider'

import configuration from './configuration'

import './App.scss'

const OidcSecureContainer = () => (
  <OidcSecure>
    <Outlet />
  </OidcSecure>
)

const App = () => {
  const homePaths = ['/home', '/']
  return (
    <div data-testid="app" className="App">
      <LanguageContextProvider>
        <ThemeProvider>
          <BrowserRouter>
            <OidcProvider
              configuration={configuration.oidcConfiguration}
              loadingComponent={Loading}
              authenticatingComponent={Loading}
              callbackSuccessComponent={Loading}
              authenticatingErrorComponent={ErrorCallback}
            >
              <Header />
              <main className="main">
                <Sidebar />
                <section className="app-content">
                  <Routes>
                    {homePaths.map(path => (
                      <Route key={path} path={path} element={<Home />} />
                    ))}
                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route element={<OidcSecureContainer />}>
                      <Route path="/clients" element={<Clients />} />
                      <Route path="/events" element={<Events />} />
                      <Route path="/requests" element={<Requests />} />
                      <Route path="/failed-documents" element={<FailedDocuments />} />
                    </Route>
                  </Routes>
                </section>
              </main>
            </OidcProvider>
          </BrowserRouter>
        </ThemeProvider>
      </LanguageContextProvider>
    </div>
  )
}

export default App
