import React, { useState, useCallback } from 'react'
import { languages } from '../../model'
import { getUserLang, getMessages, getLocaleCode } from '../../i18n/i18n'
import { IntlProvider } from 'react-intl'

const initialLanguage = getUserLang(navigator.language.slice(0, 2))

export const LanguageContext = React.createContext({
  userLang: '',
  localeCode: '',
  messages: {},
  refreshLang: (language: languages) => {},
})

export const LanguageContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [userLang, setUserLang] = useState(initialLanguage)
  const [localeCode, setLocaleCode] = useState(getLocaleCode(initialLanguage))
  const [messages, setMessages] = useState(getMessages(initialLanguage))
  const refreshLanguage = useCallback((language: languages) => {
    setUserLang('fr')
    setLocaleCode(getLocaleCode(language))
    setMessages(getMessages(language))
  }, [])

  return (
    <LanguageContext.Provider
      value={{
        userLang,
        localeCode,
        messages,
        refreshLang: refreshLanguage,
      }}
    >
      <IntlProvider locale={userLang} messages={messages}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  )
}
