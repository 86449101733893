import React from 'react'
import { FormField, FormFieldDropdownProps } from '@myeh/design-system'

import './Dropdown.scss'
import { useIntl } from 'react-intl'

export interface DropDownItem {
  code: string
  labelKey: string
  dontTranslate?: boolean
}

interface DropdownProps extends Omit<FormFieldDropdownProps, 'onChange' | 'items' | 'label' | 'name'> {
  labelKey?: string
  items: DropDownItem[]
  value?: string
  mandatory?: boolean
  onChange: (selectedItemCode: string, valid: boolean) => void
  dataTestid?: string
}

const Dropdown = ({
  labelKey,
  items,
  value,
  mandatory,
  onChange,
  dataTestid,
  placeholder,
  ...props
}: DropdownProps) => {
  const onValueSelected = (selectedValue: string) => {
    onChange(selectedValue, selectedValue != null && selectedValue !== '')
  }
  const intl = useIntl()

  return (
    <FormField.Dropdown
      {...props}
      label={
        labelKey
          ? `${mandatory ? '*' : ''}${intl.formatMessage({
              id: labelKey,
            })}`
          : ''
      }
      name={labelKey || ''}
      appearance="outline"
      data-testid={dataTestid || 'combobox'}
      items={items.map(({ code, labelKey: itemKey, dontTranslate }: DropDownItem) => ({
        value: code,
        label: dontTranslate ? itemKey : intl.formatMessage({ id: itemKey }),
      }))}
      defaultValue={value ?? (placeholder ? undefined : items[0].code)}
      onChange={onValueSelected}
    />
  )
}

export default Dropdown
