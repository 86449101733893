import { Grid } from '@myeh/design-system'
import { IDocumentToMigrate } from '../../../model'
import React from 'react'
import Input from '../../molecules/Input'

export interface DocumentsMigrationRowProps {
  documentToMigrate: IDocumentToMigrate
  onChange: (document: IDocumentToMigrate) => void
  disabled: boolean
}

const DocumentsMigrationRow = ({ documentToMigrate, onChange, disabled }: DocumentsMigrationRowProps) => {
  const handleChange = (field: keyof IDocumentToMigrate, value: string) => {
    documentToMigrate[field] = value
    onChange(documentToMigrate)
  }

  return (
    <Grid.Row>
      <Grid.Column size="6">
        <Input
          type="text"
          labelKey="documents-migration-modal-signature-id"
          value={documentToMigrate.signatureId}
          changeValue={value => handleChange('signatureId', value)}
          disabled={disabled}
        />
      </Grid.Column>
      <Grid.Column size="6">
        <Input
          type="text"
          labelKey="documents-migration-modal-bond-request"
          value={documentToMigrate.bondRequestId}
          changeValue={value => handleChange('bondRequestId', value)}
          disabled={disabled}
        />
      </Grid.Column>
    </Grid.Row>
  )
}

export default DocumentsMigrationRow
